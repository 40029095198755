import React from "react"
import "../css/error404.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import  Erreur404 from "../images/erreur-404-mutuelle-MGC.png"
const NotFoundPage = () => (
  <Layout>
    <SEO title="Erreur 404" />
    <div  className={"p404"}>
      <h1>Erreur, la page demand&eacute;e n'existe pas.</h1>
      <div className={"subtitle"}>Nous vous invitons &agrave; retourner sur la page d'accueil&nbsp;<a href="https://mutuellemgc.fr">en cliquant-ici</a><span>&nbsp;ou &agrave; effectuer&nbsp;</span><a href="/recherchePage/?recherche">une nouvelle recherche</a><span>.</span></div>
      <table  border="0">
          <tbody>
          <tr>
              <td className={'first'}><img src={Erreur404} alt="Visuel erreur 404" width="110px" height="auto" /></td>
              <td className={'second'}>
                  <div className={'right-img'}>
                      <h3>Sur ce coup, nous n'avons pas assur&eacute;...<br />Mais nous couvrons bien d'autres risques !</h3>
                      <p><a href="/mutuelle-sante">Mutuelle sant&eacute;</a><br /><a href="/mutuelle-prevoyance">Mutuelle pr&eacute;voyance</a></p>
                  </div>
              </td>
          </tr>
          </tbody>
      </table>
    </div>
  </Layout>
)

export default NotFoundPage
